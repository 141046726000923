@import '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .logo {
    display: flex;
    justify-content: center;
    img {
      max-width: 280px;
    }
  }
  h4 {
    @include regular;
    text-align: center;
    color: $text-color;
    font-size: 16px;
  }
  button {
    @include btn;
    margin: 5px;
  }
}
