@font-face {
	font-family: "Inter-SemiBold";
	src: url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Medium";
	src: url("../assets/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Regular";
	src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Light";
	src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
}


@mixin bold {
  font-family: "Inter-SemiBold";
}

@mixin medium {
  font-family: "Inter-Medium";
}

@mixin regular {
  font-family: "Inter-Regular";
}

@mixin light {
  font-family: "Inter-Light";
}


@mixin h1 {
  @include medium;
  font-size: 42px;
}

@mixin label {
	font-family: "Inter-SemiBold";
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

@mixin text {
  @include regular;
  font-size: $regular;
  color: $white;
}