@import "../../styles/variables";

.container {
  background-color: $white;
  border-bottom: $border;
  height: 65px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    display: flex;
    justify-content: center;
    img {
      max-width: 153px;
    }
  }
  .links {
    display: flex;
    height: 100%;
    a {
      @include medium;
      font-size: 16px;
      text-decoration: none;
      height: 100%;
      width: 150px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        @include transition;
        color: rgba($text-color, 0.5);
        &::after {
          @include transition;
          content: '';
          height: 0px;
          width: 150px;
          background-color: rgba($primary-color, 0.3);
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      }
      &:hover {
        p {
          color: $text-color;
        }
      }
      &.active {
        p {
          color: $text-color;
          &::after {
            height: 6px;
            background-color: $primary-color;
          }
        }
      }
    }
  }
  .auth {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .user {
      @include medium;
      @include flex-center;
      background-color: $primary-color;
      height: 36px;
      width: 36px;
      border-radius: 36px;
      color: $white;
    }
    .logout {
      @include btn;
      @include flex-center;
      margin-left: 10px;
      color: $primary-color;
      font-size: 26px;
      &:hover {
        transform: scale(1.08);
      }
    }
  }
}