@import "../../styles/variables";

.container {
  width: 100%;
  height: 100%;
  .header {
    height: 60px;
    border-bottom: $border;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row {
    width: calc(100% - 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .col {
      width: 30%;
      padding: 0px 20px;
      &:nth-child(3) {
        width: 20%;
      }
      &.sort-item {
        margin: 10px 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #afafaf;
        img {
          margin-left: 6px;
          @include transition;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .list {
    overflow: auto;
    height: calc(100% - 97px);
    padding-top: 1px;
    .row {
      position: relative;
      border-bottom: $border;
      width: 100%;
      .col {
        padding: 15px 20px;
        p {
          margin: 0px;
        }
        &:first-child {
          text-transform: capitalize;
          @include medium;
        }
        &:nth-child(3) {
          padding: 0px;
        }
      }
      &:hover {
        background-color: rgba($primary-color, 0.08);
      }
      .delete {
        @include btn;
        @include flex-center;
        position: absolute;
        height: 50px;
        right: 8px;
        font-size: 20px;
        pointer-events: none;
        opacity: 0;
      }
      &:hover {
        .delete {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
}