@import '../../../styles/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        max-width: 280px;
      }
    }
    .field {
      width: 100%;
      @include field;
    }
    .error {
      color: $danger;
      font-size: 13px;
    }
    .btn {
      @include btn;
      margin-top: 10px;
    }
  }
}