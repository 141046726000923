@import '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  border: $border;
  width: calc(100% - 60px);
  max-width: 1200px;
  background-color: $white;
  height: calc(100% - 60px);
}