@import '../../styles/variables.scss';

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba($dark,.4);
}

.modal{
  position: fixed;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  .close {
    @include btn;
    position: absolute;
    top: 8px;
    right: 5px;
    svg {
      font-size: 24px;
    }
  }
}
