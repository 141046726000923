@import "../../styles/variables";

.container {
  width: 600px;
  min-height: 280px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  .close {
    @include btn;
    @include flex-center;
    background-color: $primary-color;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 33px;
    height: 33px;
    font-size: 28px;
    color: $white;
    &:hover {
      font-size: 30px;
    }
  }
  .img {
    width: 40%;
    img {
      transform: translateX(-80px) scale(1.4);
    }
  }
  .form {
    width: 60%;
    padding: 20px 30px 20px 0px;
    h3 {
      margin: 0px 0px 20px 0px;
      padding-bottom: 5px;
      border-bottom: 4px solid $primary-color;
    }
    .field {
      margin-bottom: 10px;
    }
    .name {
      color: $primary-color;
    }
    h3 {
      margin: 0px 0px 20px 0px;
      padding-bottom: 5px;
      font-size: 16px;
      border-bottom: 4px solid $primary-color;
    }
    .loader {
      @include message;
      .icon {
        @include flex-center;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        border: 2px solid $success;
        color: $success;
        font-size: 20px;
        margin-bottom: 10px
      }
      .success {
        @include bold;
        color: $success;
      }
    }
    .success {
      @include message;
      @include bold;
      color: $success;
      .icon {
        @include flex-center;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        border: 2px solid $success;
        color: $success;
        font-size: 20px;
        margin-bottom: 10px
      }
    }
    .error {
      @include message;
      @include bold;
      color: $danger;
      .icon {
        @include flex-center;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        color: $danger;
        font-size: 52px;
        margin-bottom: 10px
      }
      button {
        @include btn;
      }
    }
    button {
      float: right;
      margin-top: 20px;
    }
  }
}