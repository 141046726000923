@import "../../styles/variables";

.container {
  position: relative;
  width: 100%;
  .selected {
    width: 100%;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      position: absolute;
      right: 0px;
      @include btn;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      width: 40px;
      height: 50px;
      color: rgba($dark, 0.2);
      &.open {
        transform: rotate(180deg);
        color: $primary-color;
        z-index: 14;
      }
    }
  }
  .role {
    display: flex;
    align-items: center;
    font-size: 14px;
    img {
      width: 20px;
      margin-right: 5px;
    }
  }
  .options {
    @include transition;
    width: 100%;
    border: $border;
    background-color: $white;
    top: -1px;
    height: 0px;
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    &.open {
      height: 200px;
      opacity: 1;
      z-index: 13;
    }
    button {
      @include btn;
      width: 100%;
      background-color: $white;
      font-size: 14px;
      padding: 14px 19px;
      &.active {
        background-color: rgba($primary-color, 0.2);
      }
      &:hover {
        background-color: rgba($primary-color, 0.1);
      }
    }
  }
}