@import "../../styles/variables";

.container {
  width: 100%;
  height: 100%;
  .header {
    height: 60px;
    border-bottom: $border;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      width: 60%;
    }
    .files {
      display: flex;
      align-items: center;
      .upload {
        @include btn;
        @include medium;
        display: flex;
        align-items: center;
        text-decoration: underline;
        color: $primary-color;
        margin-right: 8px;
        img {
          margin-right: 2px;
        }
      }
    }
  }
  .row {
    width: calc(100% - 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .col {
      width: 20%;
      padding: 0px 20px;
      &:nth-child(2) {
        width: 40%;
      }
      &:nth-child(3) {
        width: 40%;
        padding: 0px;
        display: flex;
        justify-content: flex-end;
      }
      &.sort-item {
        margin: 10px 0px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #afafaf;
        img {
          margin-left: 6px;
          @include transition;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .list {
    overflow: auto;
    height: calc(100% - 97px);
    padding-top: 1px;
    .row {
      position: relative;
      border-bottom: $border;
      width: 100%;
      .col {
        padding: 10px 20px;
        p {
          margin: 0px;
        }
        &:first-child {
          text-transform: capitalize;
          @include medium;
        }
        &:nth-child(3) {
          padding: 0px;
        }
      }
      .export, .upload {
        opacity: 0;
        pointer-events: none;
      }
      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          @include btn;
          @include bold;
          margin-right: 20px;
          color: $primary-color;
          text-decoration: underline;
        }
      }
      &.isLoading {
        .export {
          opacity: 1;
          pointer-events: all;
        }
      }
      &:hover {
        background-color: rgba($primary-color, 0.08);
        .export, .upload {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}