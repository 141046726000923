@import "../../styles/variables";

.toaster {
  @include transition;
  position: fixed;
  bottom: 0;
  right: calc(50% -  170px);
  width: 300px;
  min-height: 37px;
  padding: 10px;
  margin: 20px;
  z-index: 3000;
  border-radius: $radius;
  transform: translateY(100px);
  box-shadow: $shadow;
  background-color: $primary-color;
  color: $white;
  p {
    margin: 0px;
    text-align: center;
    @include text;
    font-size: 14px;
  }
  &.success {
    background-color: $success;
    color: $white;
  }
  &.error {
    background-color: $danger;
    color: $white;
  }
  &.active {
    transform: translateY(0px);
  }
}