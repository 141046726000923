@import '../../../styles/variables.scss';

.button{
    @include btn;
    @include text;
    display: flex;
    align-items: center;
    justify-content: center;
    border : none;
    position: relative;
    border-radius: $radius;
    cursor: pointer;
    min-width: 155px;
    svg {
      margin-right: 10px;
      font-size: 16px;
    }
    &.primary {
        color: $white;
        outline: none;
        background: $primary-color;
        border-radius: 0px;
        &.loading{
          pointer-events: none;
          color : rgba(255, 255, 255, 0.592);
          background: darken($primary-color,6%) repeating-linear-gradient(60deg, transparent, transparent 10px, $primary-color 10px, $primary-color 20px);
          background-repeat: repeat;
          background-size:calc(100% + 20px) 51px;
	        background-position-x:0%;
          background-repeat:repeat-x;
	        animation: backgroundMove .6s infinite linear forwards;
        }
        &:not(.loading){
            background: $primary-color;
        }
    }

    &.secondary {
        color: $white;
        border: none;
        background: $dark;
        &.loading{
          pointer-events: none;
          color : rgba(255,255,255,.6);
          background: darken($dark,3%) repeating-linear-gradient(60deg, transparent, transparent 10px, $dark 10px, $dark 20px);
          background-repeat: repeat;
          background-size:calc(100% + 20px) 51px;
	        background-position-x:0%;
          background-repeat:repeat-x;
	        animation: backgroundMove .6s infinite linear forwards;
        }
    }


    @keyframes backgroundMove {
        0%{
            background-position-x:0%;
        }
        100% {
            background-position-x:-21px;
        }
    }

}