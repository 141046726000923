@import '../../styles/variables.scss';

.container {
  @include transition;
  position: fixed;
  top: 0px;
  right: calc(50% -  200px);
  width: 400px;
  min-height: 37px;
  margin: 20px;
  z-index: 3000;
  border-radius: $radius;
  transform: translateY(-200px);
  box-shadow: 0px 2px 60px 2px rgba(24, 24, 68, 0.3);
  background-color: $white;
  color: $white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.active {
    transform: translateY(40px);
  }
  p {
    text-align: center;
    @include medium;
    color: $dark;
    margin: 0px;
  }
  .actions {
    margin-top: 20px;
    .btn {
      @include btn;
      margin: 0px 10px;
      &.primary {
        &:last-child {
          background-color: #bebebe;
          border: 1px solid #bebebe;;
        }
      }
    }
  }
}