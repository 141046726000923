@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
    @include medium;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 6px;
    }
  }
  input {
    display: none;
  }
  .input {
    @include input;
    @include  bold;
    font-size: 14px;
    color: $primary-color;
    text-align: center;
    background: #EEF7FF;
    border: 1px dashed #B1BAD8;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      background: #c4e0fb;
    }
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

}
