@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color: $black;
  font-family: "Inter-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  font-size: 14px;
  // scrollbar-color:  rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.03);
  // scrollbar-width: 10px;
}

* {
  box-sizing: border-box;
}

.App {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  &:hover {
    background: $primary-color;
  }
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  box-shadow: 0 0 0 30px transparent inset !important;
  animation-delay: 0.1s;
  animation-name: autofill;
  animation-fill-mode: both;
}

@keyframes autofill {
  0%,100% {
      color: #666 !important;
      background: transparent;
  }
}