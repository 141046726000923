@mixin page-container {
  height: 100vh;
  width: 100%;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin input {
  @include transition;
  display: block;
  padding: 6px 0;
  border: none;
  outline: none;
  border-radius: 0px;
  width: 100%;
  caret-color: $white;
  box-sizing: border-box;
  background-color: transparent;
  border-bottom: solid 1px transparent;
  font-size: $regular;
  line-height: 17px;
  color: $white;
  &:focus{
    border-bottom: solid 1px rgba($white , 0.2);
  }
  &::placeholder {
    color : #84899A;
  }
}

@mixin field {
  margin-bottom: 12px;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin btn {
	cursor: pointer;
	@include transition;
	background-color: transparent;
  outline: none;
  border: none;
  @include regular;
  &.primary {
    @include medium;
    text-align: center;
    text-decoration: none;
    background-color: $primary-color;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    color: $white;
    border: 1px solid $primary-color;
    cursor: pointer;
    &:hover {
      background-color: $primary-color-dark;
      border: 1px solid $primary-color-dark;
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin message {
  @include flex-center;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 80px;
  p {
    margin: 0px;
  }
}

