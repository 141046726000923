@import './mixins.scss';
@import './fonts.scss';

$bg-color:  #FAFAFB;
$white: #FFFFFF;
$black: #181844;
$dark: #1C376B;
$dark-grey:#1F232E;

$text-color: #001730;

$primary-color: #004E9C;
$primary-color-dark: #003c77;

$grey:#D8D8D8;
$light-grey: #E3E3E3;
$orange: #FF8469;

$success: #4DCD98;
$danger : red;

$shadow: 0px 2px 21px 2px rgba(24, 24, 68, 0.0669799);

$lg-screen: 1400px;
$md-screen: 1200px;
$sm-screen: 1024px;
$xs-screen: 576px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 3.5px;
$radius-md: 9px;

$border: 1px solid #EAECF0;
