@import "../../styles/variables";

.container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    color: $primary-color;
    font-size: 26px;
  }
  input {
    @include regular;
    outline: none;
    border: none;
    border-bottom: 1px solid transparent;
    margin-left: 8px;
    font-size: 16px;
    &::placeholder {
      font-style: italic;
    }
    &:focus, &:hover {
      border-bottom: $border;
    }
  }
}